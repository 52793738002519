<div class="howWhereWhenBar">
	<cdx-svg-icon		
		[name]="iconName"
		size="small"
		fill="dark" 
		display="inline"
		class="icon" 		
	/>				
	<div class="message-container">
		<ng-container *ngIf="(journeyState$ | async) as journeyState">
			<span class="information-message" [ngSwitch]="journeyState">				
				<ng-container *ngSwitchCase="'NotSelected'">				
					{{ informationMessage }}				
					<a href="javascript:void(0)" 						
						(click)="handleActionButtonClick(journeyState)" 			
						[attr.aria-label]="actionMessage"
						class="actionButtonLink"
					>
						{{ actionMessage }}
					</a> before you shop.
				</ng-container>
				<ng-container *ngSwitchCase="'Selected'">				
					{{ informationMessage }}					
					<a href="javascript:void(0)" 						
						(click)="handleActionButtonClick(journeyState)" 			
						[attr.aria-label]="actionMessage"
						class="actionButtonLink"
					>
						{{ actionMessage }}
					</a>
				</ng-container>				
				<ng-container *ngSwitchCase="'Expired'">				
					<strong>{{ alertMessage }}</strong>. {{ informationMessage }}					
					<a href="javascript:void(0)" 						
						(click)="handleActionButtonClick(journeyState)" 			
						[attr.aria-label]="actionMessage"
						class="actionButtonLink"
					>
						{{ actionMessage }}
					</a>.
				</ng-container>
				<ng-container *ngSwitchCase="'Closed'">				
					<strong>{{ alertMessage }}</strong>. {{ informationMessage }}										
					<a href="javascript:void(0)" 						
						(click)="handleActionButtonClick(journeyState)" 			
						[attr.aria-label]="actionMessage"
						class="actionButtonLink"
					>
						{{ actionMessage }}
					</a>.
				</ng-container>
				<ng-container *ngSwitchDefault>					
					{{ informationMessage }}<strong>{{ locationName }}</strong>.
					<a href="javascript:void(0)" 						
						(click)="handleActionButtonClick(journeyState)" 			
						[attr.aria-label]="actionMessage"
						class="actionButtonLink"
					>
						{{ actionMessage }}
					</a>	
				</ng-container>				
			</span>
		</ng-container>
	</div>	
</div>
